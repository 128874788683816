import { useEffect, useState } from "react";
import SideNav from "../../component/sideNav/SideNav";
import Table from "../../component/table/table";

import "./specality.style.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import Transport from "../../api/transport";


export default function Specality(){

        const [rows, setRows]  = useState<any[]>([]);
        const [column, setColumns] = useState<any[]>([]);
        
        const navigation = useNavigate();

        useEffect(()=>{
            Transport.GET.getSpecialist()
                .then((res) => {
                    let lookups = res.data
                    setRows(lookups)
                })
                .catch((e) => alert(e.message))

                setColumns( getColumn(navigation));
              
        },[])

    return(
        <div className="hospitalHome">
            <SideNav name={"Specality"} navigation={navigation} />
            <div className="hospitalBody">
                <div className={"hospitalHeader"}>
                <h3 className={"h1"}>Specality List</h3>
                <Button 
                onClick={()=>{
                    
                    navigation("/CreateSpecality")
                }}
                sx={{backgroundColor: '#067996', margin: 3,fontWeight: 'bold', color: 'white'}}> Create Specality </Button>
                </div>
                <div className="tableContainer">
            <Table columns={column} data={rows} />
            </div>


            </div>
        </div>
    );
}
 function getColumn(navigation: any) {
    var column = [
      { field: 'name', headerName: 'name', width: 130 },
      { field: 'description', headerName: 'description', width: 100 },
     
    ];
  
    return column;
  }
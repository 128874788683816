import { BookIcon, DonutIcon, HomeIcon, List } from "lucide-react";


import "./sideNav.style.css";
import { Button } from "@mui/material";
import { BiDockBottom, BiDockLeft, BiHome, BiSolidHotel } from "react-icons/bi";
import { BsBookFill, BsFillHospitalFill, BsPerson, BsPersonBadge } from "react-icons/bs";
import { useState, useEffect } from "react";


export interface pages {
    name: string,
    navigation: any
}

export default function SideNav({ name, navigation }: pages) {
    const [userType, setUserType] = useState<any>("");
    useEffect(() => {
        if (localStorage.getItem("token") === null) {
            window.location.href = "/";
        } else {
            setUserType(localStorage.getItem("user_type") || "");

        }

    }, [])
    if (userType === "HOSPITAL") {
        return (

            <div className="sideNav">
                <div className="navHeader">
                <BsBookFill size={30} className="iconB" />
                    <h1 style={{ fontSize: 28 }}>App Name</h1>
                </div>

                <Button
                    onClick={() => {
                        navigation("/bookings_hospital")
                    }} sx={{ backgroundColor: (name == 'booking' ? 'rgb(81, 90, 93)' : 'transparent'), "&:hover": {
                        backgroundColor: 'rgb(81, 90, 93)'
                    }, color: 'white', margin: 5, marginBottom: 1, padding: 3, marginLeft: 0, marginTop: 0, width: '100%' }}>
                    <BookIcon className="iconB" />
                    
                    <p>Bookings </p>
                </Button>

                <Button
                    onClick={() => {
                        navigation("/doctors")
                    }}
                    sx={{ backgroundColor: (name == 'doctors' ? 'rgb(81, 90, 93)' : 'transparent'), "&:hover": {
                        backgroundColor: 'rgb(81, 90, 93)'
                    }, color: 'white', marginBottom: 1, padding: 3, marginLeft: 0, width: '100%' }}>
                    <BsPerson size={30} className="iconB" />
                    <p>Doctors</p>
                </Button>
                <Button
                    onClick={() => {
                        navigation("/Specality")
                    }}

                    sx={{ backgroundColor: (name == 'Specality' ? 'rgb(81, 90, 93)' : 'transparent'), "&:hover": {
                        backgroundColor: 'rgb(81, 90, 93)'
                    }, color: 'white', marginBottom: 1, padding: 3, marginLeft: 0, width: '100%' }}>
                    <List className="iconB" />
                    <p>Specality</p>
                </Button>

                <Button
                    onClick={() => {
                        navigation("/diagnosis")
                    }}

                    sx={{ backgroundColor: (name == 'Diagnosis' ? 'rgb(81, 90, 93)' : 'transparent'), "&:hover": {
                        backgroundColor: 'rgb(81, 90, 93)'
                    }, color: 'white', marginBottom: 1, padding: 3, marginLeft: 0, width: '100%' }}>
                    <List className="iconB" />
                    <p>Diagnosis</p>
                </Button>


                <Button
                    onClick={() => {
                        navigation("/Profile")
                    }} sx={{ backgroundColor: (name == 'Profile' ? 'rgb(81, 90, 93)' : 'transparent'), "&:hover": {
                        backgroundColor: 'rgb(81, 90, 93)'
                    }, color: 'white', marginBottom: 1, padding: 3, marginLeft: 0, width: '100%' }}>
                    <BiHome size={30} className="iconB" />
                    <p>Profile</p>
                </Button>


                <h3 style={{ position: 'absolute', color: 'white', alignSelf: 'center', margin: 'auto', textAlign: 'center', width: '100%', bottom: '19%' }}> Version 1.0.0</h3>


            </div>
        );
    } else if(userType === "HOTEL")  {
        return (

            <div className="sideNav">
                <div className="navHeader">
                    <h1 style={{ fontSize: 28 }}> Booking</h1>
                </div>

                <Button
                    onClick={() => {
                        navigation("/bookings_hotel")
                    }} sx={{ backgroundColor: (name == 'booking' ? 'rgb(81, 90, 93)' : 'transparent'), "&:hover": {
                        backgroundColor: 'rgb(81, 90, 93)'
                    }, color: 'white', margin: 5, marginBottom: 1, padding: 3, marginLeft: 0, marginTop: 0, width: '100%' }}>
                    <BookIcon className="iconB" />
                    <p>Bookings </p>
                </Button>

                <Button
                    onClick={() => {
                        navigation("/Rooms")
                    }}
                    sx={{ backgroundColor: (name == 'doctors' ? 'rgb(81, 90, 93)' : 'transparent'), "&:hover": {
                        backgroundColor: 'rgb(81, 90, 93)'
                    }, color: 'white', marginBottom: 1, padding: 3, marginLeft: 0, width: '100%' }}>
                    <BsPerson size={30} className="iconB" />
                    <p>Rooms</p>
                </Button>
               

                <Button
                    onClick={() => {
                        navigation("/Profile")
                    }} sx={{ backgroundColor: (name == 'Profile' ? 'rgb(81, 90, 93)' : 'transparent'), "&:hover": {
                        backgroundColor: 'rgb(81, 90, 93)'
                    }, color: 'white', marginBottom: 1, padding: 3, marginLeft: 0, width: '100%' }}>
                    <BiHome size={30} className="iconB" />
                    <p>Profile</p>
                </Button>


                <h3 style={{ position: 'absolute', color: 'white', alignSelf: 'center', margin: 'auto', textAlign: 'center', width: '100%', bottom: '19%' }}> Version 1.0.0</h3>


            </div>
        );
    }else {
        return (

            <div className="sideNav">
                <div className="navHeader">
                    <h1 style={{ fontSize: 28 }}> Booking</h1>
                </div>

            
                <Button
                    onClick={() => {
                        navigation("/listHotel")
                    }}
                    sx={{ backgroundColor: (name == 'hotels' ? 'rgb(81, 90, 93)' : 'transparent'), "&:hover": {
                        backgroundColor: 'rgb(81, 90, 93)'
                    }, color: 'white', marginBottom: 1, padding: 3, marginLeft: 0, width: '100%' }}>
                    <BiSolidHotel size={30} className="iconB" />
                    <p>Hotels</p>
                </Button>
               

                <Button
                    onClick={() => {
                        navigation("/listHospital")
                    }}
                    sx={{ backgroundColor: (name == 'hospitals' ? 'rgb(81, 90, 93)' : 'transparent'), "&:hover": {
                        backgroundColor: 'rgb(81, 90, 93)'
                    }, color: 'white', marginBottom: 1, padding: 3, marginLeft: 0, width: '100%' }}>
                    <BsFillHospitalFill size={30} className="iconB" />
                    <p>Hospitals</p>
                </Button>

                <Button
                    onClick={() => {
                        navigation("/profileAdmin")
                    }} sx={{ backgroundColor: (name == 'Profile' ? 'rgb(81, 90, 93)' : 'transparent'), "&:hover": {
                        backgroundColor: 'rgb(81, 90, 93)'
                    }, color: 'white', marginBottom: 1, padding: 3, marginLeft: 0, width: '100%' }}>
                    <BiHome size={30} className="iconB" />
                    <p>Profile</p>
                </Button>


                <h3 style={{ position: 'absolute', color: 'white', alignSelf: 'center', margin: 'auto', textAlign: 'center', width: '100%', bottom: '19%' }}> Version 1.0.0</h3>


            </div>
        );
    }

}
   

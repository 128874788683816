import { useEffect, useState } from "react";
import SideNav from "../../component/sideNav/SideNav";
import Table from "../../component/table/table";

import "./specality.style.css";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import CForm from "../../component/forms/form";
import { formBuilder } from "../../component/forms/prop.form";
import Transport from "../../api/transport";
import { getJson } from "../../component/forms/controller.form";
import { ActivityIcon } from "lucide-react";
import axios from "axios";


export default function  CreateSpecality(){

        const [rows, setRows]  = useState<any[]>([]);
        const [column, setColumns] = useState<any[]>([]);
        const [specialist, setSpecialist] = useState<any[]>([]);
        const [finalJson , setFinalJson] = useState<any>({});
        const [loading,setLoading] = useState<boolean>(false);
        const [doctor, setDoctor] = useState<any>(null);
        /*

{
    "image": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyVaxs3qBS_MeNaG5dioG8xKand1IJoBULoNm3UmqQHg&s",
    "name": "Radiology",
    "description": "the trusthsk ljaskldjfl sdkfr"
}
        */
        const [dataArrayData,setDataArray] = useState<formBuilder[]>([
        {
              label: "Name Of Specality",
              name: "name",
              value: "",
              type: "TextInput",
              placeholder: "Name Of Specality",
              checkListValues: [],
              onChange: () => {},
              required: false,
            },
            {
              label: "description",
              name: "description",
              value: "",
              type: "TextInput",
              placeholder: "description",
              checkListValues: [],
              onChange: () => {},
              required: false,
            }
        ]);

        const param = useParams();

        
        useEffect(() => {
         
      }, []);
      


  
       
        

    
        const navigation = useNavigate();
    return(
        <div className="hospitalHome">
            <SideNav name={"Specality"} navigation={navigation} />
            <div className="hospitalBody">
                <div className={"hospitalHeader"}>

                <h3 className={"h1"}>Create Specality</h3>
                </div>
                {
                    loading ?( <ActivityIcon className="loader" />) : 
                
               <div style={{width: 600, height: 600, backgroundColor: 'white' }}>
       
              <CForm formData={dataArrayData} callback={(updatedData: any) => {
                     var lastJson =   getJson(updatedData);
                     
                     lastJson.image = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyVaxs3qBS_MeNaG5dioG8xKand1IJoBULoNm3UmqQHg&s";
                    console.log("lastJson: ", lastJson);
                  
                      setFinalJson(lastJson);
                      }} startForm={0} endForm={5} />
            

              <Button 
              onClick={() => {
                Transport.POST.createSpecialist(finalJson).then((res:any) => {
                    console.log("res: ", res);
                    navigation("/Specality")
                } ).catch((e:any) => alert(e.message))
                
               }}
              style={{margin: 30, backgroundColor: '#04a1bf',padding: 15,color:'white',fontWeight: 'bold',width: 300}}>
                Submit
              </Button>
   
              </div>
              }


            </div>
        </div>
    );
}
 function getColumn() {
  var column = [
    { field: 'name', headerName: 'Doctor Name', width: 130 },
    { field: 'years_of_experiance', headerName: 'Years of experience', width: 100 },
    { field: 'speciality_id', headerName: 'Specialization', width: 100 },
    { field: 'consultation_fee', headerName: 'consultation fee', width: 100 },
    { field: 'image', headerName: 'photo', width: 100 },
    {
      field: 'edit',
      headerName: 'edit',
      width: 130,
      renderCell: (params: any) => {
        return (
          <Button variant="contained"  sx={{backgroundColor: 'lightblue', color: 'white', fontWeight: 'bold'}}>
            {"Edit"}
          </Button>
        );
      }, 
    }
  ];

  return column;
}
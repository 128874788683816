//react-router-dom
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  Outlet,
  useNavigate,
} from "react-router-dom";

import HospitalLogin from '../login/HospitalLogin';
import HotelLogin from '../login/HotelLogin';
import HospitalHome from "../hospital_home/HospitalHome";
import Doctors from "../doctors/doctor";
import CreateDoctor from "../doctors/createDoctor";
import Diagnosis from "../diagnosis/diagnosis";
import CreateDiagnosis from "../diagnosis/diagnosisCreate";
import Profile from "../profile/profile";
import Hotel from "../hotel/hotel";
import CreateSpecality from "../Specality/createSpecality";
import Specality from "../Specality/specality";
import BookingHospital from "../booking_hospital/bookingHospital";
import BookingHotel from "../bookings_hotel/bookingHotel";
import CreateBooking from "../booking_hospital/createBookingHospital";
import { useEffect, useState } from "react";
import Rooms from "../rooms/rooms";
import CreateRoom from "../rooms/createRooms";
import Hospitals from "../hospital_list/hospitals";
import Hotels from "../hotel_list/hotels";
import CreateHospital from "../hospital_list/createHospital";
import CreateHotel from "../hotel_list/createHotel";
import AdminProfile from "../profile/profile";

export default function MainScreen() {
  const [userType,setUserType] = useState<any>("");
  useEffect(()=>{
    if(localStorage.getItem("token") === null){
      if(window.location.pathname == "/"){
        return;
      }
      window.location.href = "/";
    }else{
      setUserType(localStorage.getItem("user_type") || "");

    }

  },[])
  return (
    <Router>
      <Routes>
        {userType === "HOSPITAL" ? (
          <>
            <Route path="/" element={<HospitalHome />} />
            <Route path="/doctors" element={<Doctors />} />
            <Route path="/CreateDoctor" element={<CreateDoctor />} />
            <Route path="/diagnosis" element={<Diagnosis />} />
            <Route path="/CreateDiagnosis" element={<CreateDiagnosis />} />
            <Route path="/Profile" element={<Profile />} />
            <Route path="/Hospital_login" element={<HospitalLogin />} />
            <Route path="/bookings_hospital" element={<BookingHospital />} />
           
            <Route path="/createBooking" element={<CreateBooking />} />
            <Route path="/Specality" element={<Specality />} />
            <Route path="/CreateSpecality" element={<CreateSpecality />} />
            <Route path="/diagnosis" element={<Diagnosis />} />
        <Route path="/CreateDiagnosis" element={<CreateDiagnosis /> } />
  
        <Route path="/doctor/edit/:userId" element={<CreateDoctor /> } />

          </>
        ) : userType === "HOTEL" ?  (
          <>
          <Route path="/Hotel_login" element={<HotelLogin />} />
          <Route path="/hotel" element={<Hotel />} />
          <Route path="/rooms" element={<Rooms />} />
          <Route path="/hotel_booking" element={<Hotel />} />
          <Route path="/CreateRoom" element={<CreateRoom />} />
          <Route path="/room/edit/:roomId"  element={<CreateRoom />} />
          <Route path="*" element={<NotFound />} />
        </>
          ): (
                <>
          <Route path="/listHotel" element={<Hotels />} />
          <Route path="/createHotel" element={<CreateHotel />} />
          <Route path="/listHospital" element={<Hospitals />} />
          <Route path="/CreateHospital" element={<CreateHospital />} />
          <Route path="/profileAdmin" element={<AdminProfile />} />
          <Route path="/room/edit/:roomId"  element={<CreateRoom />} />
          <Route path="*" element={<NotFound />} />
                </>

          ) 
          
          }
          <Route path="/" element={<HotelLogin />} />
       

      </Routes>
    </Router>
  );
}

function NotFound() {
  return (
    <div>
      <h1>404 - Page Not Found</h1>
      <p>The requested page does not exist.</p>
    </div>
  );
}
import { useEffect, useState } from "react";
import SideNav from "../../component/sideNav/SideNav";
import Table from "../../component/table/table";

import "./doctor.style.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import Transport from "../../api/transport";
import Header from "../../component/header/header";
import { link } from "fs/promises";


export default function Doctors(){
  function handleLogout(){
    console.log("logged out")
    localStorage.removeItem('token');
    navigation("/");
  }

        const [rows, setRows]  = useState<any[]>([]);
        const [column, setColumns] = useState<any[]>([]);
        
        const navigation = useNavigate();

        useEffect(()=>{
            Transport.GET.getDoctors()
                .then((res) => {
                    let lookups = res.data
                    setRows(lookups)
                })
                .catch((e) => alert(e.message))

                setColumns( getColumn(navigation));
              
        },[])

    return(
        <div className="hospitalHome">
           <Header title="Hospital Booking List" userName="John Doe" onLogout={() => handleLogout()} />
            <SideNav name={"doctors"} navigation={navigation} />
            <div className="hospitalBody">
                <div className={"hospitalHeader"}>
                <h3 className="h1">Doctor List</h3>
                <Button 
                onClick={()=>{
                    
                    navigation("/CreateDoctor")
                }}
                sx={{backgroundColor: '#067996 ', margin: 3,fontWeight: 'bold', color: 'white', "&:hover": {
                  backgroundColor: 'green '
              }}}> Create Doctor</Button>
                </div>
                <div className="tableContainer">
            <Table columns={column} data={rows} />
           </div>

            </div>
        </div>
    );
}
 function getColumn(navigation: any) {
    var column = [
      { field: 'image', headerName: 'Photo', width: 170 ,
        renderCell: (params: any) => {
            return (
            <img src={params.value} alt="photo" style={{width: 50, height: 50, borderRadius:50}} />
            );
        },
    },
      { field: 'name', headerName: 'Doctor Name', width: 230 },
      { field: 'years_of_experiance', headerName: 'Years of experience', width: 170 },
      { field: 'Speciality', headerName: 'Specialization', width: 170 },
      { field: 'consultation_fee', headerName: 'Consultation fee', width: 170 },
      
      {
        field: 'edit',
        headerName: 'Edit',
        width: 150,
        renderCell: (params: any) => {
          return (
            <Button 
                onClick={() => {
                    console.log(params.row)
                     navigation(`/doctor/edit/${params.row.id}`)
                }}
            variant="contained"  sx={{backgroundColor: '#067996 ', color: 'white', fontWeight: 'bold'}}>
              {"Edit"}
            </Button>
          );
        }, 
      }
    ];
  
    return column;
  }
import { useEffect, useState } from "react";
import SideNav from "../../component/sideNav/SideNav";
import Table from "../../component/table/table";

import "./hospital.style.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import Transport from "../../api/transport";
import Header from "../../component/header/header";


export default function Hospitals(){

  function handleLogout(){
    console.log("logged out")
    localStorage.removeItem('token');
    navigation("/");
  }
        const [rows, setRows]  = useState<any[]>([]);
        const [column, setColumns] = useState<any[]>([]);
        
        const navigation = useNavigate();

        useEffect(()=>{
            Transport.GET.getHospitals()
                .then((res) => {
                    let lookups = res.data
            
                    setRows(lookups)
                })
                .catch((e) => alert(e.message))

                setColumns( getColumn(navigation));
              
        },[])

    return(
        <div className="hospitalHome">
           <Header title="Room List" userName="John Doe" onLogout={() => handleLogout()} />
            <SideNav name={"hospitals"} navigation={navigation} />
            <div className="hospitalBody">
                <div className={"hospitalHeader"}>
                <h3 className="h1">Room List</h3>
                <Button 
                onClick={()=>{
                    
                    navigation("/CreateHospital")
                }}
                sx={{backgroundColor: '#067996 ', margin: 3,fontWeight: 'bold', color: 'white'}}> Create </Button>
                </div>
                <div className="tableContainer">
            <Table columns={column} data={rows} />
                </div>

            </div>
        </div>
    );
}
 function getColumn(navigation: any) {
    var column = [
      { field: 'name', headerName: 'Hospital Name', width: 130 },
      { field: 'region', headerName: 'Region', width: 100 },
      { field: 'zone', headerName: 'Zone', width: 100 },
      { field: 'woreda', headerName: 'Woreda', width: 100 },
    //   { field: 'image', headerName: 'photo', width: 100 ,
    //     renderCell: (params: any) => {
    //         return (
    //         <img src={params.value} alt="photo" style={{width: 50, height: 50, borderRadius:50}} />
    //         );
    //     },
    // },
      // {
      //   field: 'edit',
      //   headerName: 'edit',
      //   width: 130,
      //   renderCell: (params: any) => {
      //     return (
      //       <Button 
      //           onClick={() => {
      //               console.log(params.row)
      //                navigation(`/room/edit/${params.row.id}`)
      //           }}
      //       variant="contained"  sx={{backgroundColor: '#067996 ', color: 'white', fontWeight: 'bold'}}>
      //         {"Edit"}
      //       </Button>
      //     );
      //   }, 
      // }
    ];
  
    return column;
  }



export default function HospitalLogin(){


    return(
        <div>



            </div>
    )
}
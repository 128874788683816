import React from "react";
import { Button } from "@mui/material";
import "./header.style.css";

interface HeaderProps {
  title: string;
  userName: string;
  onLogout: () => void;
}



const Header: React.FC<HeaderProps> = ({ title, userName, onLogout }) => {
  return (
    
    <div className="header">
      {/* <div className="title">{title}</div> */}
      <div className="user-info">
        <span>Welcome, {localStorage.getItem("user_name")}</span>
        <Button onClick={onLogout} style={{backgroundColor: "c01a1a"}} variant="contained" color="secondary">
          Logout
        </Button>
      </div>
    </div>
  );
};

export default Header;

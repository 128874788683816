import { useEffect, useState } from "react";
import SideNav from "../../component/sideNav/SideNav";
import Table from "../../component/table/table";

import "./hospital.style.css";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import CForm from "../../component/forms/form";
import { formBuilder } from "../../component/forms/prop.form";
import Transport from "../../api/transport";
import { getJson } from "../../component/forms/controller.form";
import { ActivityIcon } from "lucide-react";
import axios from "axios";


export default function  CreateHospital(){

        const [rows, setRows]  = useState<any[]>([]);
        const [column, setColumns] = useState<any[]>([]);
        const [specialist, setSpecialist] = useState<any[]>([]);
       
        const [loading,setLoading] = useState<boolean>(false);
        const [doctor, setDoctor] = useState<any>(null);
        /*
    private int $id;
    //size: 11
    private string $room_number;
    //size: 250
    private string $room_type;
    //size: 250
    private string $room_price;
    //size: 250
    private string $room_status;
    //size: 250
    private int $rest_room;
        */

    const [roomForm, setRoomForm] = useState<formBuilder[]>([
      {
          label: "Name",
          name: "name",
          value: "",
          type: "TextInput",
          placeholder: "Name",
          checkListValues: [],
          onChange: () => {},
          required: true,
      },
      {
          label: "Region",
          name: "region",
          value: "",
          type: "TextInput",
          placeholder: "Region",
          checkListValues: [],
          onChange: () => {},
          required: true,
      },
      {
          label: "Zone",
          name: "zone",
          value: "",
          type: "TextInput",
          placeholder: "Zone",
          checkListValues: [],
          onChange: () => {},
          required: true,
      },
      {
          label: "Woreda",
          name: "woreda",
          value: "",
          type: "TextInput",
          placeholder: "Woreda",
          checkListValues: [],
          onChange: () => {},
          required: true,
      },
      {
          label: "Latitude",
          name: "finelatitude",
          value: "",
          type: "TextInput",
          placeholder: "Latitude",
          checkListValues: [],
          onChange: () => {},
          required: true,
      },
      {
          label: "Longitude",
          name: "finelongtiude",
          value: "",
          type: "TextInput",
          placeholder: "Longitude",
          checkListValues: [],
          onChange: () => {},
          required: true,
      },
      {
          label: "User Name",
          name: "user_name",
          value: "",
          type: "TextInput",
          placeholder: "User Name",
          checkListValues: [],
          onChange: () => {},
          required: true,
      },
      {
          label: "Password",
          name: "password",
          value: "",
          type: "TextInput",
          placeholder: "Password",
          checkListValues: [],
          onChange: () => {},
          required: true,
      }
  ]);

   const [finalJson,setFinalJson] = useState({
    "name": "hjh",
    "location": {
        "region": "sdfdsfd",
        "zone": "vcvcv",
        "woreda": "03"
    },
    "finelocation":{
        "latitude": "2343454461",
        "longtiude": "0949819587"
    },
    "user":{
        "user_name": "Zewditu",
        "password": "Zewditu",
        "user_type": "HOSPITAL"
    }
});

        const param = useParams();

        
        useEffect(() => {
          const fetchData = async () => {
              if (param.roomId) {
                  try {
                    
                      const roomRes = await Transport.GET.getRoomById(parseInt(param.roomId));
                      const roomData = roomRes.data[0];
                      
                      setDoctor(roomData);
                      
                      const newDataArray = [...roomForm];
                      newDataArray[0].value = roomData.room_number;
                      newDataArray[1].value = roomData.room_type;
                      newDataArray[2].value = roomData.room_price;
                      newDataArray[3].value = roomData.room_status;
                      newDataArray[4].value = roomData.rest_room;
                      
                     setRoomForm(newDataArray);
                     setLoading(false);
                  } catch (error: any) {
                      alert(error.message);
                  }
              } else {
                  try {
                  
                      setLoading(false);

                  } catch (error: any) {
                      alert(error.message);
                  }
              }
          };
          setLoading(true);
          fetchData();
      }, []);
      


        const getSpecalityId = (name: string, specialist: any[]) => {
            var id = 0;
            console.log("NAME: ", name);
            console.log("SPecality: ", specialist)
            specialist?.map((item: any) => {
                if(item.name == name){
                    id = item.id;
                }
            })
            return id;
        }
     
       
        

    
        const navigation = useNavigate();
    return(
        <div className="hospitalHome">
            <SideNav name={"hospitals"} navigation={navigation} />
            <div className="hospitalBody">
                <div className={"hospitalHeader"}>

                <h3>Create Hospital</h3>
                <Button sx={{backgroundColor: 'lightblue', margin: 3,fontWeight: 'bold', color: 'white'}}> Create </Button>
                </div>
                {
                    loading ?( <ActivityIcon className="loader" />) : 
                
               <div style={{width: 600, height: 600, backgroundColor: 'white' }}>
       
              <CForm formData={roomForm} callback={(updatedData: any) => {
                     var lastJson =   getJson(updatedData);
                     
                     
   
            var finalBody = {

            "name": lastJson.name,
            "location": {
                "region": lastJson.region,
                "zone": lastJson.zone,
                "woreda": lastJson.woreda
            },
            "finelocation":{
                "latitude": lastJson.latitude,
                "longtiude": lastJson.longtiude  
            },  
            "user":{

                "user_name": lastJson.user_name,
                "password": lastJson.password, 
                "user_type": "HOSPITAL"
            }
            }

                      setFinalJson(finalBody);
                      }} startForm={0} endForm={15} />
            

              <Button 
              onClick={() => {
                if(param.roomId){
            
               Transport.POST.updateRoom(finalJson,param.roomId).then((res:any) => {
                    alert("Room updated successfully")
                    navigation("/rooms")
                }).catch((e) => alert(e.message))
                }else{
                  Transport.POST.addHospital(finalJson)
                  .then((res:any) => {
                      alert("Hospital created successfully")
                      navigation("/listHospital")
                  })
                  .catch((e) => alert(e.message))
                }
              }}
              style={{margin: 30, backgroundColor: 'lightblue',padding: 15,color:'white',fontWeight: 'bold',width: 300}}>
                Submit
              </Button>
   
              </div>
              }


            </div>
        </div>
    );
}
 function getColumn() {
  var column = [
    { field: 'name', headerName: 'Doctor Name', width: 130 },
    { field: 'years_of_experiance', headerName: 'Years of experience', width: 100 },
    { field: 'speciality_id', headerName: 'Specialization', width: 100 },
    { field: 'consultation_fee', headerName: 'consultation fee', width: 100 },
    { field: 'image', headerName: 'photo', width: 100 },
    {
      field: 'edit',
      headerName: 'edit',
      width: 130,
      renderCell: (params: any) => {
        return (
          <Button variant="contained"  sx={{backgroundColor: 'lightblue', color: 'white', fontWeight: 'bold'}}>
            {"Edit"}
          </Button>
        );
      }, 
    }
  ];

  return column;
}
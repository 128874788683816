import { useEffect, useState } from "react";
import SideNav from "../../component/sideNav/SideNav";
import Table from "../../component/table/table";

import "./booking.style.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import Transport from "../../api/transport";
import Header from "../../component/header/header";


export default function BookingHospital(){

        const [rows, setRows]  = useState<any[]>([]);
        const [column, setColumns] = useState<any[]>([]);
        const [updatedValue, setUpdatedvalue] = useState(false);
        
        const navigation = useNavigate();

        useEffect(()=>{
            Transport.GET.getAppointment()
                .then((res) => {
                    let lookups = res.data
                    setRows(lookups)
                })
                .catch((e) => alert(e.message))
                  
                setColumns( getColumn(navigation, ()=>{ setUpdatedvalue(!updatedValue);}));
              
        },[])
        useEffect(()=>{
            Transport.GET.getAppointment()
                .then((res) => {
                    let lookups = res.data
                    setRows(lookups)
                })
                .catch((e) => alert(e.message))

                setColumns( getColumn(navigation, ()=>{ setUpdatedvalue(!updatedValue);}));
              
        },[updatedValue])
        function handleLogout(){
          console.log("logged out")
          localStorage.removeItem('token');
          navigation("/");
        }

    return(
      
        <div className="hospitalHome">
         <Header title="Hospital Booking List" userName="John Doe" onLogout={() => handleLogout()} />
            <SideNav name={"booking"} navigation={navigation} />
            <div className="hospitalBody">
                <div className={"hospitalHeader"}>
                <h3  className={"h1"} >Hospital Booking List</h3>
                <br/>
                {/* <Button 
                onClick={()=>{
                    
                    navigation("/CreateBookingHospital")
                }}
                sx={{backgroundColor: 'lightblue', margin: 3,fontWeight: 'bold', color: 'white'}}> Create </Button> */}
                
                </div>
                <div className="tableContainer">
            <Table columns={column} data={rows} />
            </div>


            </div>
        </div>
    );
}


 function getColumn(navigation: any,update: any) {
    var column = [
      { field: 'doctor_name', headerName: 'Doctor Name', width: 110,  headerClassName: 'tableHeader' },
      { field: 'booking_date', headerName: 'Booking Date', width: 130,  headerClassName: 'tableHeader' },
      { field: 'booking_time', headerName: 'Booking Time', width: 130,  headerClassName: 'tableHeader' },
      { field: 'booking_price', headerName: 'Booking Price', width: 100,  headerClassName: 'tableHeader' },
      { field: 'booking_bank', headerName: 'Booking Bank', width: 130,  headerClassName: 'tableHeader' },
      { field: 'booking_patient_name', headerName: 'Patient Name', width: 120,  headerClassName: 'tableHeader' },
      { field: 'booking_payment_status', headerName: 'Booking Payment Status', width: 170,  headerClassName: 'tableHeader',
     renderCell: (params: any) => {
        return (
            <Button 
              
            variant="contained"  sx={ { padding: 5, backgroundColor: params.value == "NOT_PAID" ? '#530909 ' : 'green', color: 'white', fontWeight: 'bold', "&:hover": {
              backgroundColor: '#bd2020e3 '
          }, borderRadius: 5}}>
              {params.value}
            </Button>
          );
        }
     },
     
      { field: 'booking_status', headerName: 'Booking Status', width: 150,
    renderCell: (params: any) => {  
        return (
            <Button 
              
            variant="contained"  sx={{padding: 5, backgroundColor: params.value == "PENDING" ? 'orange' : ' #005b1794', color: 'white', "&:hover": {
              backgroundColor: params.value == "PENDING" ? 'orange' : '#0b5309 ' 
          }, fontWeight: 'bold' }}>
              {params.value}
            </Button>
          );
        }
    },
    
      {
        field: 'edit',
        headerName: '',
        width: 130,
        renderCell: (params: any) => {
      

          return (
            <Button 
                onClick={() => {
                 
                    console.log(params.row)
                   Transport.PATCH.updateAppointment({booking_status: "APPROVED"}, params.row.id).then((res) => {
                    let lookups = res.data;
                    update();
                }).catch((e) => alert(e.message))



                }}
            variant="contained"  sx={{ display: params.row["booking_status"] == "APPROVED"? 'none' : 'flex' ,  backgroundColor: 'GREEN', color: 'white', fontWeight: 'bold'}}>
              {"Approve"}
            </Button>

            
          );
        }, 
      },
      {
        field: 'edit2',
        headerName: '',
        width: 130,
        renderCell: (params: any) => {
      

          return (
            <Button 
                onClick={() => {
                 
                    console.log(params.row)
                   Transport.PATCH.updateAppointment({booking_status: "CHANGED"}, params.row.id).then((res) => {
                    let lookups = res.data;
                    update();
                }).catch((e) => alert(e.message))



                }}
            variant="contained"  sx={{ display: params.row["booking_status"] == "APPROVED"? 'none' : 'flex' ,  backgroundColor: 'brown', color: 'white', fontWeight: 'bold'}}>
              {"Change"}
            </Button>
            
            
          );
        }, 
      }
    ];
  
    return column;
  }
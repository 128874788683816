import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { tableProp } from './prop.table';
import "./style.table.css";
export default function Table(dataProp: tableProp) {
  return (
    <div style={{  width: '100%' }}>
      <DataGrid
        rows={dataProp.data}
        
        columns={dataProp.columns.map((column) => ({
          ...column,
          headerClassName: 'headerCell', // Apply the custom class to the header cells
        }))}
     
        initialState={{
         
          pagination: {
            paginationModel: { page: 0, pageSize: 15 },
          },
        }}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        checkboxSelection={false}
        density='standard'
      />
    </div>
  );
}
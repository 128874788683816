import { useEffect, useState } from "react";
import SideNav from "../../component/sideNav/SideNav";
import Table from "../../component/table/table";
import { getColumn, getRows } from "./diagnosis.controlller";

import "./diagnosis.style.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import CForm from "../../component/forms/form";
import { formBuilder } from "../../component/forms/prop.form";
import { getJson } from "../../component/forms/controller.form";
import Transport from "../../api/transport";
import Header from "../../component/header/header";


export default function  CreateDiagnosis(){
  function handleLogout(){
    console.log("logged out")
    localStorage.removeItem('token');
    navigation("/");
  }
        const [rows, setRows]  = useState<any[]>([]);
        const [column, setColumns] = useState<any[]>([]);
        const [finalJson,setFinalJson] = useState<any>({});

        useEffect(()=>{
                setRows(getRows());
                setColumns( getColumn());
             

        },[])
        /*
        {
    "name": "MRI",
    "image": "https://wtop.com/wp-content/uploads/2022/02/01-mri1-e1644277957352.jpg",
    "description":"this is a test"
}
*/
const [mriForm , setMriForm] = useState<any[]>([
  {
    label: "Name",
    name: "name",
    value: "",
    type: "TextInput",
    placeholder: "Name",
    checkListValues: [],
    onChange: () => {},
    required: false,
  },
  {
    label: "Description",
    name: "description",
    value: "",
    type: "TextInput",
    placeholder: "Description",
    checkListValues: [],
    onChange: () => {},
    required: false,
  },
  {
    label: "file",
    name: "image",
    value: "",
    type: "file",
    placeholder: "file",
    checkListValues: [],
    onChange: () => {},
    required: false,
  },
]);

       
     
    
        const navigation = useNavigate();
    return(
        <div className="hospitalHome">
           <Header title="Hospital Booking List" userName="John Doe" onLogout={() => handleLogout()} />
            <SideNav name={"Diagnosis"} navigation={navigation} />
            <div className="hospitalBody">
                <div className={"hospitalHeader"}>

                <h3 className={"h1"}>Add Diagnosis</h3>
                
                </div>
                <div style={{width: 600, height: 400, backgroundColor: 'white' }}>
                  <CForm formData={mriForm} callback={(data: any) => {

                    var jsonValue = getJson(data);
                    console.log(jsonValue);
                    
                    setFinalJson(jsonValue);

                  }} startForm={0} endForm={3} />
            

              <Button  
              onClick={() => {
                Transport.POST.addDiagnosis(finalJson).then((res:any) => {
                  console.log(res);
                }
                ).catch((e:any) => {
                  console.log(e);
                }
                );
                
              }}
              style={{margin: 30, backgroundColor: '#04a1bf',padding: 15,color:'white',fontWeight: 'bold',width: 300}}>
                Submit
              </Button>
   
              </div>


            </div>
        </div>
    );
}
import { useEffect, useState } from "react";
import SideNav from "../../component/sideNav/SideNav";
import Table from "../../component/table/table";

import "./diagnosis.style.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import CForm from "../../component/forms/form";
import { formBuilder } from "../../component/forms/prop.form";
import Transport from "../../api/transport";


export default function  AdminProfile(){

    const [hotelCont, setHotelCont] = useState<number>(0);
    const [hospitalCont, setHospitalCont] = useState<number>(0);

    useEffect(() => {
        const fetchData = async () => {
           Transport.GET.getHospitals().then((res) => {
            setHospitalCont(res.data.length);
           });
              Transport.GET.gethotels().then((res) => {
                setHotelCont(res.data.length);
              });
          };
          fetchData();
    }, []);
    
    
        const navigation = useNavigate();
    return(
        <div className="hospitalHome">
            <SideNav name={"Profile"} navigation={navigation} />
            
            <div style={{width:'80%',marginLeft:'20%', height: '40%', display: 'flex', flexDirection:'row', alignItems: 'center'}}>
          <div style={{width:270,height:210,backgroundColor:'white', margin: 10, borderRadius:15, boxShadow:'2px 4px  4px lightgray '}}>
               <h2 style={{fontSize:60,alignItems:'center',verticalAlign:'center',marginTop:40}}>{hotelCont}</h2>
                <p style={{fontSize:15}}>Hotels</p>
          </div>

          <div style={{width:270,height:210,backgroundColor:'white', margin: 10, borderRadius:15, boxShadow:'2px 4px  4px lightgray '}}>
               <h2 style={{fontSize:60,alignItems:'center',verticalAlign:'center',marginTop:40}}>{hospitalCont}</h2>
                <p style={{fontSize:15}}>Hospitals</p>
          </div>

          {/* <div style={{width:270,height:210,backgroundColor:'white', margin: 10, borderRadius:15, boxShadow:'2px 4px  4px lightgray '}}>
               <h2 style={{fontSize:60,alignItems:'center',verticalAlign:'center',marginTop:40}}>15</h2>
                <p style={{fontSize:15}}>Specialists</p>
          </div> */}


          {/* <div style={{width:270,height:210,backgroundColor:'white', margin: 10, borderRadius:15, boxShadow:'2px 4px  4px lightgray '}}>
               <h2 style={{fontSize:60,alignItems:'center',verticalAlign:'center',marginTop:40}}>30</h2>
                <p style={{fontSize:15}}>Average Daily Patient</p>
          </div> */}
            </div>
        </div>
    );
}
import { useEffect, useState } from "react";
import SideNav from "../../component/sideNav/SideNav";
import Table from "../../component/table/table";

import "./diagnosis.style.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import Transport from "../../api/transport";
import { getRows } from "./diagnosis.controlller";
import Header from "../../component/header/header";

export default function Diagnosis(){
  function handleLogout(){
    console.log("logged out")
    localStorage.removeItem('token');
    navigation("/");
  }
    const [rows, setRows]  = useState<any[]>([]);
    const [column, setColumns] = useState<any[]>([]);

    useEffect(()=>{
        Transport.GET.getDiagosis()
            .then((res) => {
                let rowdata = res.data;
                setRows(rowdata);
            })
            .catch((e) => alert(e.message));

        setColumns(getColumn());   
    },[]);

    const navigation = useNavigate();

    return(
        <div className="hospitalHome">
           <Header title="Hospital Booking List" userName="John Doe" onLogout={() => handleLogout()} />
            <SideNav name={"Diagnosis"} navigation={navigation} />
            <div className="hospitalBody">
                <div className={"hospitalHeader"}>
                    <h3 className={"h1"}>Diagnostics List</h3>
                    <Button 
                        onClick={() => navigation("/CreateDiagnosis")}
                        sx={{backgroundColor: '#04a1bf', margin: 3,fontWeight: 'bold', color: 'white'}}
                    >
                        Create Diagnostics
                    </Button>
                </div>
                <div className="tableContainer">
                    <Table columns={column} data={rows} />
                </div>
            </div>
        </div>
    );
}

function getColumn() {
    var column = [
        { field: 'name', headerName: 'Doctor Name', width: 130 },
        { field: 'description', headerName: 'Years of experience', width: 150 },
        { 
            field: 'image', 
            headerName: 'photo', 
            width: 100, 
            renderCell: (params: any) => {
                return (
                    <img src={params.value} style={{width: 50, height: 50}} />
                );
            },
        }
    ];

    return column;
}

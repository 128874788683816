import axios from "axios";
// https://bookingexpress.forward-et.com
// http://192.168.8.108:4000
export const baseUrl = `https://bookingexpress.forward-et.com`,
    headers = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Authorization": localStorage.getItem("token"),
    };



const Transport = {
    PATCH: {
        updateAppointment: (data: any, id: any) =>
            axios({
                url: baseUrl + `/Booking/updateByid/${id}`,
                method: "PATCH",
                headers,
                data: JSON.stringify(data)
            }),
        updateAmbaselsData: (data : any, uuid: string) =>
            axios({
                url: baseUrl + `/ambasels/${uuid}`,
                method: "PUT",
                data,
            }),
        updateBatiData: (data : any ,uuid: string) =>
            axios({
                url: baseUrl + `/batis/${uuid}`,
                method: "PUT",
                data,
            }),
        updateTizitasData: ( data : any ,uuid: string) =>
            axios({
                url: baseUrl + `/tizitas/${uuid}`,
                method: "PUT",
                data,
            }),
        updateAnchihoyesData: ( data: any ,uuid: string) =>
            axios({
                url: baseUrl + `/anchihoyes/${uuid}`,
                method: "PUT",
                data
            })
    },
    GET:{
        getAppointment: () => {
            return axios({
                url: baseUrl + `/Booking/byHospital/appointment`,
                method: "GET",
                headers
            });
        },
        getRoomById: (id: number) => {
            return axios({
                url: baseUrl + `/Room/getByid/${id}`,
                method: "GET",
                headers
            });
        },
        getRooms: () =>
            axios({
                url: baseUrl + "/Room",
                method: "GET",
                headers
            }),
        getDignosis: () =>
            axios({
                url: baseUrl + `/Diagnosis`,
                method: "GET",
            }),
        getDoctorById: (id: number) => {
            return axios({
                url: baseUrl + `/Doctors/getByid/${id}`,
                method: "GET",
            });
        },
        getSpecialist: () =>
        axios({
            url: baseUrl + `/Speciality`,
            method: "GET",
        }),
        getDiagosis: () =>
            axios({
                url: baseUrl + `/Dignosis`,
                method: "GET",
            }),
            getDoctors: () =>
                    axios({
                        url: baseUrl + "/Doctors",
                        method: "GET",
      }),
      getHospitals: () =>
      axios({
          url: baseUrl + "/Hospitals",
          method: "GET",
}),
        gethotels: () =>
            axios({
                url: baseUrl + `/Hotels`,
                method: "GET"
            }),
    },
    POST: {
        addHotel: (data: any) =>
            axios({
                url: baseUrl + `/Hotels`,
                method: "POST",
                data,
            }),
        addHospital: (data: any) => 
        axios({
            url: baseUrl + `/Hospitals`,
            method: "POST",
            data,
        }),
        updateRoom: (data: any, id: any) =>
            axios({
                url: baseUrl + `/Room/updateByid/${id}`,
                method: "PATCH",
                headers,
                data: JSON.stringify(data)
            }),
        addRoom: (data: any) =>
            axios({
                url: baseUrl + "/Room",
                method: "POST",
                data,
                headers
            }),
        login: (data: any) =>
            axios({
                url: baseUrl + `/User/login`,
                method: "POST",
                data,
            }),
        addDiagnosis: (data: any) =>
            axios({
                url: baseUrl + `/Dignosis`,
                method: "POST",
                data,
            }),
        createSpecialist: (data: any) =>
            axios({
                url: baseUrl + `/Speciality`,
                method: "POST",
                data,
            }),
        updateDoctor: (data: any, id: any) =>
            axios({
                url: baseUrl + `/Doctors/updateByid/${id}`,
                method: "PATCH",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data)
            }),
        addNewDoctor: (data: any) =>
            axios({
                url: baseUrl + `/Doctors`,
                method: "POST",
                data,
                headers
            }),
        addNewAmbaselMusic: (data: any) =>
            axios({
                url: baseUrl + `/ambasels`,
                method: "POST",
                data,
            }),
        addNewBatiMusic: (data: any) =>
            axios({
                url: baseUrl + `/batis`,
                method: "POST",
                data,
            }),
        addNewTizitaMusic: (data: any) =>
            axios({
                url: baseUrl + `/tizitas`,
                method: "POST",
                data,
            }),
        addNewAnchihoyeMusic: (data: any) =>
            axios({
                url: baseUrl + `/anchihoyes`,
                method: "POST",
                data,
            }),
    },
    DELETE: {
        removeAmbaselMusic: (uuid: string) =>
            axios({
                url: baseUrl + `/ambasels/${uuid}`,
                method: "DELETE",
            }),

        removeBatiMusic: (uuid: string) =>
            axios({
                url: baseUrl + `/batis/${uuid}`,
                method: "DELETE",
            }),

        removeTizitaMusic: (uuid: string) =>
            axios({
                url: baseUrl + `/tizitas/${uuid}`,
                method: "DELETE",
            }),

        removeAnchihoyeMusic: (uuid: string) =>
            axios({
                url: baseUrl + `/anchihoyes/${uuid}`,
                method: "DELETE",
            }),
    },
};

export default Transport;

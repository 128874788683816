import { Button, Input } from "@mui/material";

import "./login.style.css";
import { useNavigate, useNavigation } from "react-router-dom";
import Transport from "../../api/transport";
import { useState } from "react";


export default function HotelLogin(){

    const navigation = useNavigate();


    const [userName,setUserName] = useState("");
    const [password,setPassword] = useState("");

    return(
        <div className="loginScreen">
            
            <div className="loginCard">
                    <h1 style={{padding:15, color: 'white', borderRadius: 10,width:'90%'}}>Manage Your Booking</h1>
                <Input onChange={(e: any)=>{
                    setUserName(e.target.value);
                }} className="Input" placeholder="UserName" />
               
                <Input onChange={(e: any) => {
                    setPassword(e.target.value);
                }} type="password" className="Input" placeholder="Password" />
                <a href="#"> Forgot Your Password?  </a>
                <Button 
                style={{padding:6,width:"80%",color:'white',background:'#067996',fontWeight:'bold'}}
                    onClick={async()=>{
                       Transport.POST.login({"user_name": userName, "password": password}).then(async (res) => {
                            if(res.status === 200){
                                if(res.data.user_type === "HOSPITAL"){
                                    console.log("this is the data",res.data)
                                    await localStorage.setItem("token", res.data.token);
                                    await localStorage.setItem("user_type", res.data.user_type);
                                    await localStorage.setItem("user_name", res.data.user_name);
                                    if (localStorage.getItem("token") ){
                                        window.location.href= "/doctors";
                                    } 
                                }else if(res.data.user_type === "HOTEL"){
                                    await localStorage.setItem("token", res.data.token);
                                    await localStorage.setItem("user_type", res.data.user_type);
                                    await localStorage.setItem("user_name", res.data.user_name);

                                    window.location.href= "/rooms";
                                }else if(res.data.user_type === "ADMIN"){
                                    await localStorage.setItem("token", res.data.token);
                                    await localStorage.setItem("user_type", res.data.user_type);
                                    await localStorage.setItem("user_name", res.data.user_name);

                                    window.location.href= "/listHospital";
                                }else{
                                    alert("Invalid User")
                                }
                                 
                            }
                          }
                        ).catch((err: any) => {
                            alert("Invalid User Name or Password")
                        
                        })   

                    }}
                className="loginButton">
                    Login
                </Button>
            </div>
        
        </div>
    )
}

import React from 'react';
import { DatePickerProp } from './prop.DatePicker';
import "./style.DatePicker.css";
export default function DatePicker(datePicker: DatePickerProp){

    return(
        <div className="datePicker">
            <label>{datePicker.title}</label>
            <input 
            value={datePicker.value}
            onChange={(tes)=>{
                datePicker.onChange(tes.target.value);
            }} type="date" />

            </div>
    )
}
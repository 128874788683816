import React from "react";
import { baseUrl } from "../../../api/transport";

export default function CFileUpload(props: {
  uploadType: string;
  lable: string;
  value: string;
  onChange: (value: string) => void;
}) {
  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("fileToUpload", file);

    try {
      const response = await fetch(baseUrl+"/files", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        var imageUrl = await response.text();
        imageUrl = imageUrl.replace(/"/g, "");
        // remove \n from the start of the string
        imageUrl = imageUrl.replace(/\n/, "");
        props.onChange(imageUrl);
      } else {
        console.error("Failed to upload file.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <div className="uploadUI">
      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        {props?.lable}
      </label>
      <input
        className="block p-2 font-sans font-normal w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
        aria-describedby="file_input_help"
        name="file_input"
        type="file"
        accept=".png,.jpg,.jpeg"
        onChange={handleFileChange}
      />
      <p
        className="mt-1 self-start font-sans text-xs font-semibold text-xs text-gray-500 dark:text-gray-300"
        id="file_input_help"
      >
        PNG, JPEG or JPG.
      </p>
    </div>
  );
}

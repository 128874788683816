import { useEffect, useState } from "react";
import SideNav from "../../component/sideNav/SideNav";
import Table from "../../component/table/table";

import "./booking.style.css";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import CForm from "../../component/forms/form";
import { formBuilder } from "../../component/forms/prop.form";
import Transport from "../../api/transport";
import { getJson } from "../../component/forms/controller.form";
import { ActivityIcon } from "lucide-react";
import axios from "axios";
import Header from "../../component/header/header";


export default function  CreateBooking(){
  function handleLogout(){
    console.log("logged out")
  }
  const [rows, setRows]  = useState<any[]>([]);
        const [column, setColumns] = useState<any[]>([]);
        const [specialist, setSpecialist] = useState<any[]>([]);
        const [finalJson , setFinalJson] = useState<any>({});
        const [loading,setLoading] = useState<boolean>(false);
        const [doctor, setDoctor] = useState<any>(null);
        const [dataArrayData,setDataArray] = useState<formBuilder[]>([
          {
            label: "Doctor Name",
            name: "image",
            value: "",
            type: "file",
            placeholder: "Doctor Name",
            checkListValues: [],
            onChange: () => {},
            required: false,
          },
          {
              label: "Doctor Name",
              name: "name",
              value: "",
              type: "TextInput",
              placeholder: "Doctor Name",
              checkListValues: [],
              onChange: () => {},
              required: false,
            },
            {
              label: "Years of experiance",
              name: "years_of_experiance",
              value: "",
              type: "TextInput",
              placeholder: "Years of experiance",
              checkListValues: [],
              onChange: () => {},
              required: false,
            },
            {
              label: "Consultation Fee",
              name: "consultation_fee",
              value: "",
              type: "TextInput",
              placeholder: "Cost",
              checkListValues: [],
              onChange: () => {},
              required: false,
            },
            {
              label: "Specialist",
              name: "Specialist",
              value: "",
              type: "dropdown",
              placeholder: "Specialist",
              checkListValues: [],
              checkList: ["one", "two", "three"],
              onChange: () => {},
              required: false,
            }
        ]);

        const param = useParams();

        
        useEffect(() => {
          const fetchData = async () => {
              if (param.userId) {
                  try {
                    
                      const doctorRes = await Transport.GET.getDoctorById(parseInt(param.userId));
                      const doctorData = doctorRes.data[0];
                      
                      setDoctor(doctorData);
                      
                      const newDataArray = [...dataArrayData];
                      newDataArray[1].value = doctorData.name;
                      newDataArray[2].value = doctorData.years_of_experiance;
                      newDataArray[3].value = doctorData.consultation_fee;
                      newDataArray[newDataArray.length - 1].value = doctorData.speciality_id;
      
                      const specialistRes = await Transport.GET.getSpecialist();
                      const specialistData = specialistRes.data;
                      
                      setSpecialist(specialistData);
      
                      newDataArray[newDataArray.length - 1].checkList = specialistData.map((item: any) => item.name);
                      setDataArray(newDataArray);
                     setLoading(false);
                  } catch (error: any) {
                      alert(error.message);
                  }
              } else {
                  try {
                      const specialistRes = await Transport.GET.getSpecialist();
                      const specialistData = specialistRes.data;
      
                      setSpecialist(specialistData);
      
                      const newDataArray = [...dataArrayData];
                      newDataArray[newDataArray.length - 1].checkList = specialistData.map((item: any) => item.name);
                      setDataArray(newDataArray);
                      setLoading(false);

                  } catch (error: any) {
                      alert(error.message);
                  }
              }
          };
          setLoading(true);
          fetchData();
      }, []);
      


        const getSpecalityId = (name: string, specialist: any[]) => {
            var id = 0;
            console.log("NAME: ", name);
            console.log("SPecality: ", specialist)
            specialist?.map((item: any) => {
                if(item.name == name){
                    id = item.id;
                }
            })
            return id;
        }
     
       
        

    
        const navigation = useNavigate();
    return(
        <div className="hospitalHome">
           <Header title="Hospital Booking List" userName="John Doe" onLogout={() => handleLogout()} />
            <SideNav name={"doctors"} navigation={navigation} />
            <div className="hospitalBody">
                <div className={"hospitalHeader"}>

                <h3>Create Doctor</h3>
                <Button sx={{backgroundColor: 'lightblue', margin: 3,fontWeight: 'bold', color: 'white'}}> Create </Button>
                </div>
                {
                    loading ?( <ActivityIcon className="loader" />) : 
                
               <div style={{width: 600, height: 600, backgroundColor: 'white' }}>
       
              <CForm formData={dataArrayData} callback={(updatedData: any) => {
                     var lastJson =   getJson(updatedData);
                     
                     var speciality_id = getSpecalityId(lastJson.Specialist, specialist);
                     var finalObj =  {
                      "name": lastJson.name,
                      "speciality_id": speciality_id,
                      "image": lastJson.image,
                      "years_of_experiance": lastJson.years_of_experiance ,
                      "consultation_fee": lastJson.consultation_fee
                  }
                    console.log("lastJson: ", finalObj);
                  
                      setFinalJson(finalObj);
                      }} startForm={0} endForm={5} />
            

              <Button 
              onClick={() => {
                if(param.userId){
            
               Transport.POST.updateDoctor(finalJson,param.userId).then((res:any) => {
                    alert("Doctor updated successfully")
                }).catch((e) => alert(e.message))
                }else{
                  Transport.POST.addNewDoctor(finalJson)
                  .then((res:any) => {
                      alert("Doctor created successfully")
                  })
                  .catch((e) => alert(e.message))
                }
              }}
              style={{margin: 30, backgroundColor: 'lightblue',padding: 15,color:'white',fontWeight: 'bold',width: 300}}>
                Submit
              </Button>
   
              </div>
              }


            </div>
        </div>
    );
}
 function getColumn() {
  var column = [
    { field: 'name', headerName: 'Doctor Name', width: 130 },
    { field: 'years_of_experiance', headerName: 'Years of experience', width: 100 },
    { field: 'speciality_id', headerName: 'Specialization', width: 100 },
    { field: 'consultation_fee', headerName: 'consultation fee', width: 100 },
    { field: 'image', headerName: 'photo', width: 100 },
    {
      field: 'edit',
      headerName: 'edit',
      width: 130,
      renderCell: (params: any) => {
        return (
          <Button variant="contained"  sx={{backgroundColor: 'lightblue', color: 'white', fontWeight: 'bold'}}>
            {"Edit"}
          </Button>
        );
      }, 
    }
  ];

  return column;
}
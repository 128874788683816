import { useEffect, useState } from "react";
import SideNav from "../../component/sideNav/SideNav";
import Table from "../../component/table/table";
import Transport from "../../api/transport";

import "./hospitalHome.style.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";


export default function HospitalHome(){

        const [rows, setRows]  = useState<any[]>([]);
        const [column, setColumns] = useState<any[]>([]);

        useEffect(()=>{
            Transport.GET.getHospitals()
                .then((res) => {
                    let lookups = res.data
                    setRows(lookups)
                })
                .catch((e) => alert(e.message))
                setColumns(getColumn());
            
        },[])

        const navigation = useNavigate();
    return(
        <div className="hospitalHome">
            <SideNav name={"booking"}  navigation={navigation} />
            <div className="hospitalBody">
                <div className={"hospitalHeader"}>

                <h3>Booking List</h3>
                </div>
            <Table columns={column} data={rows} />


            </div>
        </div>
    );
}
 function getColumn() {
    var column = [
      { field: 'name', headerName: 'Doctor Name', width: 130 },
      { field: 'years_of_experiance', headerName: 'Years of experience', width: 100 },
      { field: 'speciality_id', headerName: 'Specialization', width: 100 },
      { field: 'consultation_fee', headerName: 'consultation fee', width: 100 },
      { field: 'image', headerName: 'photo', width: 100 },
      {
        field: 'edit',
        headerName: 'edit',
        width: 130,
        renderCell: (params: any) => {
          return (
            <Button variant="contained"  sx={{backgroundColor: 'lightblue', color: 'white', fontWeight: 'bold'}}>
              {"Edit"}
            </Button>
          );
        }, 
      }
    ];
  
    return column;
  }
import { useEffect, useState } from "react";
import SideNav from "../../component/sideNav/SideNav";
import Table from "../../component/table/table";

import "./rooms.style.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import Transport from "../../api/transport";
import Header from "../../component/header/header";


export default function Rooms(){

  function handleLogout(){
    console.log("logged out")
    localStorage.removeItem('token');
    navigation("/");
  }
        const [rows, setRows]  = useState<any[]>([]);
        const [column, setColumns] = useState<any[]>([]);
        
        const navigation = useNavigate();

        useEffect(()=>{
            Transport.GET.getRooms()
                .then((res) => {
                    let lookups = res.data
            
                    setRows(lookups)
                })
                .catch((e) => alert(e.message))

                setColumns( getColumn(navigation));
              
        },[])

    return(
        <div className="hospitalHome">
           <Header title="Room List" userName="John Doe" onLogout={() => handleLogout()} />
            <SideNav name={"doctors"} navigation={navigation} />
            <div className="hospitalBody">
                <div className={"hospitalHeader"}>
                <h3 className="h1">Room List</h3>
                <Button 
                onClick={()=>{
                    
                    navigation("/CreateRoom")
                }}
                sx={{backgroundColor: '#067996 ', margin: 3,fontWeight: 'bold', color: 'white'}}> Create </Button>
                </div>
                <div className="tableContainer">
            <Table columns={column} data={rows} />
                </div>

            </div>
        </div>
    );
}
 function getColumn(navigation: any) {
    var column = [
      { field: 'room_number', headerName: 'Room Number', width: 130 },
      { field: 'room_type', headerName: 'Room Type', width: 100 },
      { field: 'room_price', headerName: 'Price / Night', width: 100 },
      { field: 'room_status', headerName: 'Room Status', width: 100 },
      { field: 'rest_room', headerName: 'Bath Rooms', width: 100 },
    //   { field: 'image', headerName: 'photo', width: 100 ,
    //     renderCell: (params: any) => {
    //         return (
    //         <img src={params.value} alt="photo" style={{width: 50, height: 50, borderRadius:50}} />
    //         );
    //     },
    // },
      {
        field: 'edit',
        headerName: 'edit',
        width: 130,
        renderCell: (params: any) => {
          return (
            <Button 
                onClick={() => {
                    console.log(params.row)
                     navigation(`/room/edit/${params.row.id}`)
                }}
            variant="contained"  sx={{backgroundColor: '#067996 ', color: 'white', fontWeight: 'bold'}}>
              {"Edit"}
            </Button>
          );
        }, 
      }
    ];
  
    return column;
  }